$neutral00:#FFFFFF;
$neutral01:#f7f8fa;
$neutral02:#F2F3F5;
$neutral03:#E5E6EB;
$neutral04:#DADEE5;
$neutral05:#E6EBF2;
$neutral06:#B6B9BF;
$neutral07:#818181;
$neutral08:#606060;
$neutral09:#3c3c3c;
$neutral10:#041A2F;

$main-background-color:#161616FF;
$secondary-background-color:#2B2B2BFF;

$main-font-color:$neutral03;
$secondary-font-color:$neutral06;

$purpleColor:#8531DFFF;

$line:#3c3c3c;
