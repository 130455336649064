.beamContainer {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 20px; /* Space between grid items */
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.beam-properties {
  grid-column: span 2; /* Make the properties span across two columns */
  font-family: Arial, sans-serif;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.beam-properties h2,
.beam-properties h3 {
  text-align: center;
  color: #333;
}

.beam-properties label {
  display: block;
  margin-bottom: 10px;
  color: #555;
}

.beam-properties input {
  width: calc(100% - 20px);
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.point-loads {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.point-loads label {
  flex: 1;
}

.delete-button {
  background-color: transparent;
  color: red;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 0 10px;
}

.delete-button:hover {
  color: darkred;
}

.function-button {
  display: flex;
  grid-column: span 2;
  justify-content: center;
  align-items: center;
  width: 30%;
  padding: 12px;
  margin-top: 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.function-button:hover {
  background-color: #218838;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.function-button:active {
  background-color: #1e7e34;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.function-button:focus {
  outline: none;
}

/* Styles for the beam image */
.beam-image {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.beam-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Add some margin to the bottom of the images to avoid overlap */
/* .beam-image:not(:last-child) {
  margin-bottom: 20px;
} */
