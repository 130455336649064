@import "./color.scss";
@import './common.scss';

.part1{
    margin:auto;
    width: 1280px;
    padding:20px;
    min-height: calc(100vh - 80px - 40px);
    position: relative;
    // display: flex;
    .text{
        // width: 60%;
        margin: 20% 1% 10% 5%;
        display:block;
        h1{
            @extend .valueText
        }
    }
    .mainbackground{
        width: 80%;
        position: absolute;
        bottom:5%;
        right: 0%;  
    }
}

.part2{
    margin:auto;
    width: 100%;
    padding:20px;
    display: flex;
    min-height: calc(100vh - 80px - 40px);
    align-items: center;
    text-align: center;
    img{
        width: 50%;
        height: auto;
        margin-right: 20px;
        object-fit: contain;
    }
    .container {
        display: grid;
        margin:auto;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        // width: 600px; /* Adjust as needed */
        // height: 500px; /* Adjust as needed */
        border: 1px solid transparent; /* Optional, for debugging */
        .products{
            display: flex;
            padding: 30px;
            img{
                width: 280px;
                object-fit: contain;
            }
            h2{
                width: 280px;
                align-items: center;
                // line-height: 300px;
            }
            .textarea{
                display: flex;
                align-items: center;
                h2{
                    margin-bottom: 12px;
                }
            }
        }
    }

    .container.products {
        box-sizing: border-box; /* 确保边框包含在单元格的尺寸内 */
    }

    .container .products:nth-child(1) {
        border-bottom: 1px solid $line;
        border-right: 1px solid $line;
        cursor: pointer;
    }

    .container .products:nth-child(2) {
        border-bottom: 1px solid $line;
        border-left: 1px solid $line;
    }

    .container .products:nth-child(3) {
        border-right: 1px solid $line;
        border-top: 1px solid $line;
    }
    .container .products:nth-child(4) {
        border-top: 1px solid $line;
        border-left: 1px solid $line;
    }
}


@media only screen and (max-width: 1080px) {
    .mainbackground{
        display: none;
        // width: 80%;
        // position: absolute;
        // bottom:5%;
        // left: 2%;  
        // opacity: 0.2;
        // animation: run 15s infinite linear;
    }
    .container {
        display: block !important;
        // margin:auto;
        img{
            width: 180px;
            object-fit: contain;
        }
        h2{
            width: 180px;
            align-items: center;
            // line-height: 300px;
        }
    }

    .container .products:nth-child(1) {
        border:0 !important;
        border-bottom: 1px solid $line !important;
        // border-right: 1px solid $line;
        cursor: pointer;
    }

    .container .products:nth-child(2) {
        border:0 !important;
        border-bottom: 1px solid $line !important;
        // border-left: 1px solid $line;
    }

    .container .products:nth-child(3) {
        border:0 !important;
        border-bottom: 1px solid $line !important;
        // border-top: 1px solid $line;
    }
    .container .products:nth-child(4) {
        border:0 !important;
        // border-top: 0px solid $line;
        // border-left: 0px solid $line;
    }

}