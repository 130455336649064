@import "./color.scss";

.tipsArea{
    // margin:15vh 20px 0 40px;
    opacity: 0.6;
    margin:auto;
    // width: 500px;
    // padding-bottom: 80px;
    z-index: 3;
    .tipsubtitle{
        border-bottom: 1px solid $neutral09;
        padding-bottom: 10px;
    }
    .tipBlocks{
        display: grid;
        grid-template-columns: auto auto;
    }
    .tipBlock{
        border-bottom: 1px solid $neutral09;
        padding: 10px 0;
        display: flex;
        width: 100%;
        .tipIndex{
            margin:0 10px;
        }
        .tips{
            padding-right: 10px;
        }
        h2{
            font-size: 26px;
        }
    }
}

.imageSection{
    z-index: 10;
    position: relative;
}

.example{
    text-align: center;
    // cursor: pointer;;
    opacity: 0.6;
    // margin-right: 20px;
}

.picDisplay{
    display: flex;
    // width: calc(100% - 500px + 100px);
    // width: 100vw;
    position: relative;
    margin-top: 50px;
    overflow: hidden;
    margin-left: -50px;
    margin-top: -120px;
    // box-shadow: inset 5em 1em gold;
    
}

.pics{
    animation: run 15s infinite linear;
    width: 1000px;
    display: flex;
    z-index: 2;


}
.picDisplayCover2:hover ~ .pics{
    // animation-play-state: paused;
    display: none;
}
.pause{
    animation-play-state: paused;
}

.picDisplayCover{
    background: linear-gradient( $main-background-color 10%,transparent,transparent,  $main-background-color);
    position: absolute;
    top:0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 500px;
}
@keyframes run{
    // to {
    //     transform: translate(0);
    // }
    to {
        transform: translate(-1000px);
    }
}

.detailAera{
    margin:10vh 20px 10vh 40px;
    display: flex;
}
.videoDisplay{
    text-align: center;
}
.homepagePic{
    // margin-top: 50px;
    // width: 100px;
    height: 500px;
    z-index: 1;
}

.fireantPics{
    margin:200px auto;
    text-align: center;
    max-width: 1200px;
    img{
        width: 400px;
        object-fit: contain;
    }
}