@import "./color.scss";
@import './font.scss';

body{
    min-width: calc(100vw - 50px);
    // min-height: calc(100vh - 20px);
    background-color: $main-background-color;
    padding: 0;
    margin:0;
    position: relative;
    // padding:25px;
}

.bigTitle{
    text-align: center;
}

.mainBody{
    // height: 100px;
    position: relative;
    z-index: 9;
    margin-top: 80px;
    // padding-top:30px;
    overflow: hidden;
    min-height: calc(100vh - 80px);
}

.page{
    position: relative;
    // min-height: calc(100vh - 20px);
    // margin-top: 150px;
    // margin-bottom: 150px;
}

.loadingPage{
    width: 100vw;
    height: 100vh;
    background-color:  $main-background-color;
    opacity: 0.9;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 99;
    padding-top: 50vh;
    // padding-left:20vw;
    text-align: center;
    margin: auto;
}
