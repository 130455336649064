@import "./color.scss";

footer{
    position: relative;
    bottom: 0;    
    background-color: $secondary-background-color;
    // opacity: 0.6;
    width: calc(100vw - 40px);
    height: 130px;
    padding:10px 20px;
    display: flex;
    // position: absolute;
    bottom: 0;
    justify-content: space-between;
    z-index: 999;
}

.footerTitle{
    margin-bottom: 10px;
}

.footerLeft{
    display: flex;
    // align-content: space-between;
    height: 103px;
    // height: 100%;
    flex-direction: column;
    justify-content: space-between;
}

.footerclick{
    cursor: pointer;
}

.footercover{
    box-shadow: 0 0 20px 20px $main-background-color;
}