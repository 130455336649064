@import "./color.scss";

.headercover{
    z-index: 999;
    width: 100%;
    height: 70px;
    position: fixed;
    top: 0;
}

.header{
    height: 56px;
    padding:12px 44px;
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    align-items: center;
    cursor: pointer;
    z-index: 999;
    // background-color: $main-background-color;
    background: linear-gradient(
        to bottom, /* Direction of the gradient */
        $main-background-color 0%, /* Opaque red at the top */
        $main-background-color 100% /* Transparent red at the bottom */
    );
    opacity: 0.8;
    .subNav{
        // display: none;
        transform: translateY(-10px);
        transition: opacity 0.3s ease, transform 0.3s ease;
        visibility: hidden;
        opacity: 0;
        line-height: 30px;
        background-color:$secondary-background-color;
        padding: 0 16px;
        border-radius: 5px;
        transform: translateY(0);
        visibility: visible;
        .subText{
            opacity: 0.6;
            cursor: initial;
        }
    }
    // .nav:hover {
    //     .subNav{
    //         display: block;
    //         opacity: 1;
    //         transform: translateY(0);
    //         visibility: visible;
          
    //     }
    //     // color:red;
    // }

    .nav:hover .subNav,
    .nav:focus-within .subNav {
        // display: block;
        display: block;
            opacity: 1;
            transform: translateY(0);
            visibility: visible;
    }

}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.contacts{
    height: 28px;
    
}

.headerLogo{
    height: 38px;
    width: 38px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.titleArea{
    display: flex;
    text-align: center;
    .title{
        color:$main-font-color
    }
    .subTitle{
        color:$secondary-font-color
    }
    img {
        width: 60px;
        height: auto;
        margin-right: 10px;
    }
}

.navs{
    display: flex;
    .nav{
        margin:0 10px;
        // opacity: 0.8;
    }
}

.subNav{
    position: absolute;
}

