@import "./color.scss";

.info{
    position: relative;
    img{
        border-radius: 50%;
        width: 150px;
        padding:15px 25px;
        transition: transform 1s ease-out;
    }
    .imgFocus{
        cursor: pointer;
        transition: transform 1s ease-out;
        transform: scale(1.3);
    }
    .hover{
        opacity: 0.6;
    }
}

.cards{
    display: flex;
    margin:50px calc((100vw - 550px) / 2) 50px;
    justify-content:space-between;
}


.aboutUs{
    position: relative;
    z-index: 99;
    h1{
        margin: 20px;
    }
    .subTitle{
        white-space: pre-line;
        margin-top: 12px;
    }
}

.infoCard{
    color:$neutral06;
    width: 80vw;
    margin:0 auto 60px;
    text-align: left;
    min-height: 400px;
}


.desctipTeam{
    width: 60%;
    margin:0 auto 10px;
    color:$neutral06
}

.titleAboutus{
    cursor: pointer;
}
