@import "./color.scss";


html {
    font-size: 14px; // Base font size for scaling using rem units
}
body {
    font-family: 'Helvetica Neue', Arial, sans-serif; // Example of a clean, readable font family
    line-height: 1.6; // Provides enough space between lines
    color: #333; // Default text color
}

.editbody{
    display: flex;
    gap:20px;
    margin:20px;
    .tabel{
        min-height: 40vh;
        overflow-y: scroll;
        margin-top: 20px;
        width: 100%;
        white-space: normal;
    }
    .computing{
        overflow-y: scroll;
        min-height: 30vh;
    }
}
.textcontent{
    white-space: nowrap;
    width: 340px;
    position: fixed;
    height: calc(100vh - 80px - 24px - 20px);
}
.tiptapArea{
    width:calc(100vw - 340px);
    margin-left: 340px;
}

.ProseMirror {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    margin: 10px;
    border: 1px solid transparent;
    min-height: 60vh;
    overflow-y: auto;

    // General body text
    p {
        font-size: 1rem; // Scales based on the base font size (16px)
        margin-bottom: 1.5rem; // Adds spacing between paragraphs
        line-height: 1.6; // Increases legibility for paragraphs
    }
    
    h1, h2 {
        color: black;
        margin-bottom: 1rem;
    }

    h1 {
        font-size: 2rem; // 32px based on 16px base font size
        line-height: 1.2; // Tighter line height for headings
    }

    h2 {
        font-size: 1.75rem; // 28px based on 16px base font size
        line-height: 1.3;
    }

    h3 {
        font-size: 1.5rem; // 24px for h3
        margin-bottom: 1rem;
    }

    h4, h5, h6 {
        font-size: 1.25rem; // 20px for smaller headings
        margin-bottom: 0.75rem;
    }

    ul, ol {
        padding-left: 20px;
        margin-bottom: 1rem;
    }

    li {
        margin-bottom: 0.5rem;
    }

    // img {
    //     // max-width: 100%; // Ensure image never exceeds the container width
    //     height: auto; // Maintain aspect ratio
    //     display: inline; // Remove any inline spacing
    //     margin: auto; // Center images and add vertical spacing
    //     border-radius: 15px; // Optional: Rounded corners for aesthetic
    //     // border: solid
    // }
}

.ProseMirror:focus{
    outline: 2px solid $purpleColor;
    box-shadow: 0 0 1px 5px white;
}

.btns{
    text-align: center;
    margin: 5px 30px 5px 20px;
    // display: flex;
    // justify-content: space-between;
    // gap:10px
    // .generateBtn{
    //     display: flex;
    //     gap:10px
    // }
}

.progressTips{
    color:$purpleColor;
    margin-bottom: 10px;
    font-size: 20px;
}

.loadingPage{
    padding-top: 40vh !important;
}

.generatepdf{
    position: absolute;
    bottom: 0;
    right: 0;
}

h5{
    cursor: pointer;
}

.dynamicBtn{
    bottom: 0;
    transition: bottom 0.5s,;
}


/* Styling for the imageContainer */
[data-type='image-container'] {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 equal-width columns */
    gap: 5px; /* Space between the columns */
    // margin: 20px 0;
    // background-color: black;
}
  
  /* Styling for each figure */

figure {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    // height: 450px; /* Fixed height for each figure */
    width: 100%; /* Make figure width 100% of the container */
    overflow: hidden; /* Ensure content doesn't overflow */
    // background-color: #3b2c2c;
}

figure:hover {
    transform: scale(1.05); /* Slightly enlarge the figure on hover */
    background-color: #aecfba; /* Change background color on hover */
    border-radius: 15px;
}
  
  /* Styling for the image inside the figure */
figure img {
    max-width: 100%;
    height: auto;
    object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
    margin: auto; // Center images and add vertical spacing
    border-radius: 15px; // Optional: Rounded corners for aesthetic
    // border: solid
}
  
  /* Styling for the caption inside the figure */
imageCaption {
    font-size: 1em;
    color: #080808;
    margin-top: 8px;
    text-align: center;
}