@import "./color.scss";

body{
    font-family: "Poppins";
}

@font-face{
    font-family: 'Calli'; // --自定义名字就好
    src: url("../font/value.otf"); // --下载的字体文件路径
}

h1{
    font-size: 58px;
    font-weight: 700;
    color:$neutral03;
}

.title{
    font-size: 24px;
    font-weight: 600;
    color:$neutral03;
}

.subTitle{
    font-size: 16px;
    font-weight: 400;
}

h2{
    font-size: 48px;
    font-weight: 600;
    color:$neutral04;
    padding:0px;
    margin:2px 0;
}

h3{
    font-size: 38px;
    font-weight: 600;
    color:$neutral06;
    padding:0px;
    margin:8px 0;
}

h4{
    font-size: 24px;
    font-weight: 400;
    color:$neutral06;
    padding:0px;
    margin:5px 0;
}

h5{
    font-size: 16px;
    font-weight: 500;
    color:$neutral06;
    padding:0px;
    margin:3px 0;
}

h6{
    font-size: 14px;
    font-weight: 400;
    color:$neutral04;
    padding:0px;
    margin:2px 0;
}


.subText{
    font-size: 12px;
    font-weight: 400;
    color:$neutral04;
    padding:0px;
    // margin:0.5px 0;
}

.center{
    text-align: center;
}

.valueText{
    font-family: 'Calli';
    font-size: 80px;
}

@media only screen and (max-width: 1080px) {
    h1{
        font-size: 48px;
        font-weight: 700;
        color:$neutral03;
    }
    
    .title{
        font-size: 18px;
        font-weight: 600;
        color:$neutral03;
    }
    
    .subTitle{
        font-size: 14px;
        font-weight: 400;
    }
    
    h2{
        font-size: 38px;
        font-weight: 600;
        color:$neutral04;
        padding:0px;
        margin:2px 0;
    }
    
    h3{
        font-size: 28px;
        font-weight: 600;
        color:$neutral06;
        padding:0px;
        margin:8px 0;
    }
    
    h4{
        font-size: 18px;
        font-weight: 400;
        color:$neutral06;
        padding:0px;
        margin:5px 0;
    }
    
    h5{
        font-size: 14px;
        font-weight: 500;
        color:$neutral06;
        padding:0px;
        margin:3px 0;
    }
    
    h6{
        font-size: 12px;
        font-weight: 400;
        color:$neutral04;
        padding:0px;
        margin:2px 0;
    }
    
    
    .subText{
        font-size: 12px;
        font-weight: 400;
        color:$neutral04;
        padding:0px;
        // margin:0.5px 0;
    }
    
    .center{
        text-align: center;
    }
    
    .valueText{
        font-family: 'Calli';
        font-size: 80px;
    }
  }