@import "./color.scss";

.logobackgroundLimit{
    height: calc(100vh - 80px);
    width: 100vw;
    // overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: spin 200s linear 200s 15 alternate;
    animation: spin 200s linear infinite;
}

@-webkit-keyframes spin{
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.logobackground{
    opacity: 0.02;
    z-index: 0;
    transform: scale(50,50) rotate(20deg);
    overflow: hidden;

}

.contact{
    width: 100%;
    text-align: center;
    margin-top: 60px;
    .contactUs{
        // width: 100%;
        margin:20px auto;
        display: inline-block;
        position: relative;
        z-index: 99;
        // text-align: left;
        color:$neutral06;
        .subTitle{
            margin-bottom: 20px;
        }
        .contactForm{
            margin:0 auto;
            display: inline-block;
            color:white !important;
        }
    }
}

.textField{
    width:300px;
}

.cardbackground{
    margin:20px 0 100px !important;
    background: #ecece6 !important;
    padding: 30px 20px !important;
    // opacity: 0.9 !important;
    box-shadow: 0px 2px 1px -1px #ecece6,0px 1px 1px 0px #ecece6,0px 1px 3px 0px #ecece6 !important;
}

>>> .Mui-focused{
    color: '#8531DFFF' !important;
    border-color:'#8531DFFF' !important;
}
.MuiOutlinedInput-notchedOutline.Mui-focused {
    color: '#8531DFFF';
    border-color:'#8531DFFF'
  }

.sendbtn{
    margin:0 auto;
    // width: 272px !important;
    // float: right;
}

.responseText{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 40px;
    text-align: start;
}
.icon{
    width: 200px;
}